<template>
  <div id="wrapper">
    <CFBeacon :AdditionalInfo="CFAdditionalInfo" />
    <Navigation activePage="websearch" @search_submit="onSearchSubmitted" :searchTerm="searchTerm"/>
    
    <div id="content" class="defaultfont">
      <Results :items="SponsoredResults" :adsRelatedTo="searchTerm" />
      <hr v-if="SponsoredResults" />
      <Results :items="OrganicResults" />
    </div>
    
    <Pagginator @gotoPage="gotoPage" />
    <!-- <Pagginator :offset="offset" @gotoPage="gotoPage" /> -->
    
    <Footer />
  </div>
</template>

<script>
import CFBeacon from "../components/CFBeacon";
import Navigation from "../components/Navigation";
import Results from "../components/Results";
import Pagginator from "../components/Pagginator";
import Footer from "../components/Footer";
import _ from "lodash";


export default {
  //name: "WebSearch",
    components: {
      CFBeacon,
      Navigation,
      Results,
      Pagginator,
      Footer,
  },
  props: {
    searchTerm: String,
    pageToken: String,
    // offset: Number, 
    channel: String,
    apiURL: String,
  },
  data:() =>({
    // searchResults: null,
    OrganicResults: null,
    SponsoredResults: null,
    CFAdditionalInfo: null,
  }),
  created(){
    this.getSearchResultsDefounced = _.debounce(this.getSearchResults, 500)
  },
  beforeMount() {
    // console.log('beformount')
    this.getSearchResultsDefounced()
    
  },
  computed: {
    // currentPage: function(){
    //   return this.offset > 0 ? (this.offset/10)+1 : 1
    // },
  },
  watch: {
    searchTerm() {
      // console.log('watch: searchTerm changed')
      this.getSearchResultsDefounced()
    },
    pageToken() {
      console.log('watch: pageToken changed')
      this.getSearchResultsDefounced()
    }
  },
  methods: {
    gotoPage(page){
      console.log('begin gotoPage', page)
      if (page == 'previosPage' && this.CFAdditionalInfo.PrevPageToken != undefined) {
        console.log('gotoPage', page)
        this.$router.push({
          //name:'WebSearch',
          query:{
            q:this.searchTerm,
            pt:this.CFAdditionalInfo.PrevPageToken
          }
        })
      }else if(page == 'nextPage' && this.CFAdditionalInfo.NextPageToken != undefined) {
        console.log('gotoPage', page)
        this.$router.push({
          //name:'WebSearch',
          query:{
            q:this.searchTerm,
            pt:this.CFAdditionalInfo.NextPageToken
          }
        })
      }
    },
    onSearchSubmitted(q) {
      // console.log('search subbitted q=', q)
      if(q != this.searchTerm) {
        // this.$router.push('/websearch.html?q='+q)
        //this.$router.push({name:'WebSearch', query:{q:q}})
        this.$router.push({query:{q:q}})
      }
  
    },
    async getSearchResults() {
      console.log('getSearchResults')
      console.log('router=', this.$router)
      //is there any query?
      if (!this.searchTerm) {
        return
      }
      
      const mkt = this.$store.state.market
      const endpoint = process.env.VUE_APP_CF_API +
        this.apiURL+
        '?mkt='+mkt+
        // 'offset='+this.offset+ 
        '&q='+this.searchTerm+
        '&n='+this.channel+ 
        '&pt='+this.pageToken
      console.log(`fetch(${endpoint})`)
      const response = await fetch(endpoint)
      if (!response.ok) {
        console.error('response not ok:', response)
        return
      }

      const respJson = await response.json()
      
      this.OrganicResults = respJson.OrganicResults.Items
      this.SponsoredResults = respJson.SponsoredResults.Items
      this.CFAdditionalInfo = respJson.AdditionalInfo
      console.log(respJson)
    }
  }
}
</script>

<style scoped>
#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

#content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 40px;
  width: 100%;
}

.defaultfont {
  font-family: Arial, sans-serif;
}
</style>