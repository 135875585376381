<template>
  <div class="pagination">
      <ul> 
        <li class="prev pagginator-items" @click="$emit('gotoPage','previosPage')"><img src="../assets/img/icons/droparrow.svg" alt=""></li>  
        <!-- <li class="pagginator-items" v-for="page in pagginatorPages" :key="page" @click="$emit('gotoPage',page)" :class="{activepage: page == currentPage}">{{page}}</li> -->
        <li class="next pagginator-items" @click="$emit('gotoPage','nextPage')"><img src="../assets/img/icons/droparrow.svg" alt=""></li>
      </ul>
    </div>
</template>

<script>
export default {
  name:"Pagginator",
  props: {
      // offset:Number,
  },
//   computed: {
//     currentPage: function(){
//       return this.offset > 0 ? (this.offset/10)+1 : 1
//     },
//     previosPage: function(){
//       return this.currentPage-1>0 ? this.currentPage-1: 1
//     },
//     nextPage: function(){
//       return this.currentPage + 1
//     },
//     pagginatorPages: function() {
//       let startIndex;
//       if(this.currentPage==1 || this.currentPage==2){
//         startIndex=1
//       } else {
//         startIndex=this.currentPage-2
//       }

//       let result = Array()
//       for(let n=startIndex; n<=startIndex;n++) {
//         result.push(n)
//       }

//       return result
//     }
//   },
}
</script>

<style>

</style>