<template>
   <div id="content-wrap" class="wrap">
      <div v-if="isAds" class="adsDisclamer">
        Ads related to: {{ adsRelatedTo }}
      </div>
        <div class="resultsrow" v-for="(item, index) in items" :key="index">
          <img :src="item.PixelUrl" >
          <div class="resultscontent">
            <div v-if="isAds" class="resultstitle" :data-yiid="item.ProviderImpressionId">
              <a :href="item.TargetedUrl" v-html="item.Title"></a>
            </div>
            <div v-else class="resultstitle">
              <a :href="item.TargetedUrl" v-html="item.Title"></a>
            </div>

            <div class="undertitle">
              <span class="smallurl">{{ item.DisplayUrl }} </span>
              <p class="resultsp">{{ item.Description }}</p>
            </div>
          </div>  
        </div>
      </div>
</template>

<script>
export default {
    name:"Results",
    props: {
        items: Array,
        adsRelatedTo: String
    },
    computed: {
      isAds() {
        return this.items && this.items.length > 0 && this.adsRelatedTo && this.adsRelatedTo.length >0
      }
    }
}
</script>

<style>
.adsDisclamer {
  font-style: italic;
  font-weight: 100;
  font-size: 12px;
  margin-top: 25px;
}
</style>