<template>
<img v-if="AdditionalInfo"
    class="beacon"
    height="1"
    width="1"
    :src="`https://search.yahoo.com/beacon/geop/p?s=1197808038&ysid=${this.AdditionalInfo.ProviderSearchId}&traffic_source=${this.AdditionalInfo.ProviderTrafficSource}`" 
/>
</template>

<script>
export default {
    name:"CFBeacon",
    props: {
        AdditionalInfo: Object,
    },
    components: {
    },
    watch: {
        AdditionalInfo() {
            this.insertBeaconCode()
        }
    },
    methods: {
        insertBeaconCode() {
            console.log('insertBeaconCode');
            if(!this.AdditionalInfo || !this.AdditionalInfo.ProviderTrafficSource || ! this.AdditionalInfo.ProviderSearchId) {
                console.log('no additional info for CFBeacon')
                return
            }
            (function (w, d, t, x, m, l, p) 
            { w['XMLPlusObject'] = m;
            w[m] = w[m] || function () 
                { (w[m].q = w[m].q || []).push(arguments) },
                w[m].l = 1 * new Date();
                l = d.createElement(t), p = d.getElementsByTagName(t)[0];
                l.type = "text/javascript";
                l.async = 1;
                l.defer = 1;
                l.src = x;
                p.parentNode.insertBefore(l, p) })(window, document, 'script', 'https://s.yimg.com/ds/scripts/xmlp.js', 'xmlp');
                // eslint-disable-next-line no-undef
                xmlp('init', this.AdditionalInfo.ProviderTrafficSource, this.AdditionalInfo.ProviderSearchId);
        }
    }
}
</script>

<style scoped>
    img.beacon {
        position: absolute;
        visibility: hidden;
    }
</style>