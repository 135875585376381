<template>
  <div id="wrapper">
    <Navigation activePage="settings" />
    <div id="content" class="settingspage">
      <div class="wrap">
        <div class="settingsrow">
          <div class="col">
            <p class="headerp">Search region</p>
            <p class="description">Your search results come from this region</p>
          </div>
          <div class="col2">
            <select v-model="selectedMarket">
              <option
                v-for="option in markets"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="buttons">
          <!-- <div class="button cancel">Cancel</div> -->
          <div class="button save" @click="saveMarket">Save</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

export default {
  name: "Settings",
  components: {
    Navigation,
    Footer,
  },
  beforeMount() {
    this.selectedMarket = this.$store.state.market;
  },
  data: () => ({
    selectedMarket: "en-US",
    markets: [
      { value: "es-AR", text: "Argentina" },
      { value: "en-AU", text: "Australia" },
      { value: "de-AT", text: "Austria" },
      { value: "nl-BE", text: "Belgium NL" },
      { value: "fr-BE", text: "Belgium FR" },
      { value: "pt-BR", text: "Brazil" },
      { value: "en-CA", text: "Canada EN" },
      { value: "fr-CA", text: "Canada FR" },
      { value: "es-CL", text: "Chile" },
      { value: "da-DK", text: "Denmark" },
      { value: "fi-FI", text: "Finland" },
      { value: "fr-FR", text: "France" },
      { value: "de-DE", text: "Germany" },
      { value: "zh-HK", text: "Hong Kong" },
      { value: "en-IN", text: "India" },
      { value: "en-ID", text: "Indonesia" },
      { value: "it-IT", text: "Italy" },
      { value: "ja-JP", text: "Japan" },
      { value: "ko-KR", text: "Korea" },
      { value: "en-MY", text: "Malaysia" },
      { value: "es-MX", text: "Mexico" },
      { value: "nl-NL", text: "Netherlands" },
      { value: "en-NZ", text: "New Zealand" },
      { value: "no-NO", text: "Norway" },
      { value: "zh-CN", text: "China" },
      { value: "pl-PL", text: "Poland" },
      { value: "en-PH", text: "Philippines" },
      { value: "ru-RU", text: "Russia" },
      { value: "en-ZA", text: "South Africa" },
      { value: "es-ES", text: "Spain" },
      { value: "sv-SE", text: "Sweden" },
      { value: "fr-CH", text: "Switzerland FR" },
      { value: "de-CH", text: "Switzerland DE" },
      { value: "zh-TW", text: "Taiwan" },
      { value: "tr-TR", text: "Turkey" },
      { value: "en-GB", text: "United Kingdom" },
      { value: "en-US", text: "United States" },
      { value: "es-US", text: "United States ES" },
    ],
  }),
  // watch: {
  //   selectedMarket(newValue) {
  //     this.$store.commit('setMarket', newValue)
  //   },
  // },
  methods: {
    saveMarket() {
      this.$store.commit('setMarket', this.selectedMarket)
    }
  }
};
</script>

<style scoped>
#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

#content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 40px;
  width: 100%;
}

.defaultfont {
  font-family: Arial, sans-serif;
}

.settingspage {
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 40px !important;
}

.settingsrow {
  display: flex;
  margin: 0 0 30px 0;
  width: 100%;
  font-size: 16px;
}

.settingsrow select {
  height: 50px;
  padding: 0 25px;
  width: 60%;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  border-radius: 60px;
  font-family: "Poppins", sans-serif;
  -webkit-appearance: none;
  outline: none;
  background: url("../assets/img/icons/droparrow.svg") no-repeat center right
    20px;
  background-size: 12px;
}

.headerp {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.col {
  width: 40%;
  flex: 0 0 40%;
  padding-right: 30px;
}

.col2 {
  width: 60%;
  flex: 0 0 60%;
}

.col,
.col2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.description {
  font-size: 12px;
  color: #949494;
  line-height: 15px;
  margin-top: 3px;
}

.buttons {
  display: inline-flex;
}

.button {
  padding: 5px 25px;
  border-radius: 60px;
  line-height: 40px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.3s ease;
  background: #eee;
}

.button:hover {
  background: #ddd;
}

.save {
  background: #8fb54c;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

.save:hover {
  background: #a9d063;
}

.cancel {
  background: #eee;
  cursor: pointer;
}
</style>