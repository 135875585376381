<template>
  <div id="nav">
    <div class="navsearch">
      <div class="hamburger" @click="isModalMenuVisible = !isModalMenuVisible">
        <svg
          enable-background="new 0 0 375.636 375.635"
          version="1.1"
          viewBox="0 0 375.636 375.635"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m41.013 228.82c-22.617 0-41.013-18.387-41.013-41.007 0-22.608 18.396-41.007 41.013-41.007s41.013 18.398 41.013 41.007c-1e-3 22.62-18.396 41.007-41.013 41.007z"
          />
          <path
            d="m185.51 228.82c-22.617 0-41.013-18.387-41.013-41.007 0-22.608 18.396-41.007 41.013-41.007 22.613 0 41.013 18.398 41.013 41.007-1e-3 22.62-18.4 41.007-41.013 41.007z"
          />
          <path
            d="m334.62 228.82c-22.613 0-41.013-18.387-41.013-41.007 0-22.608 18.399-41.007 41.013-41.007s41.013 18.398 41.013 41.007c0 22.62-18.399 41.007-41.013 41.007z"
          />
        </svg>
      </div>
      <div class="wrap">
        <a href="/">
          <div class="logo"></div>
        </a>

        <form class="searchbox" id="searchForm">
          <input
            v-model="formSearchTerm"
            type="text"
            class="searchTerm"
            placeholder="What are you looking for?"
            @submit.prevent="$emit('search_submit', formSearchTerm)"
          />
          <button
            type="submit"
            class="searchButton"
            @click.prevent="$emit('search_submit', formSearchTerm)"
          >
            <svg
              enable-background="new 0 0 250.313 250.313"
              version="1.1"
              viewBox="0 0 250.313 250.313"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m244.19 214.6l-54.379-54.378c-0.289-0.289-0.628-0.491-0.93-0.76 10.7-16.231 16.945-35.66 16.945-56.554 0-56.837-46.075-102.91-102.91-102.91s-102.91 46.075-102.91 102.91c0 56.835 46.074 102.91 102.91 102.91 20.895 0 40.323-6.245 56.554-16.945 0.269 0.301 0.47 0.64 0.759 0.929l54.38 54.38c8.169 8.168 21.413 8.168 29.583 0 8.168-8.169 8.168-21.413 0-29.582zm-141.28-44.458c-37.134 0-67.236-30.102-67.236-67.235 0-37.134 30.103-67.236 67.236-67.236 37.132 0 67.235 30.103 67.235 67.236s-30.103 67.235-67.235 67.235z"
                clip-rule="evenodd"
                fill-rule="evenodd"
              />
            </svg>
          </button>
        </form>

        <div v-if="isModalMenuVisible" id="modalmenu">
          <ul>
            <li><a href="terms.html">Terms of Service</a></li>
            <li><a href="privacy.html">Privacy</a></li>
            <li><a href="contact.html">Contact Us</a></li>
            <li><a href="settings.html">Settings</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navsettings">
      <div class="wrap">
        <SearchCategories :activePage="activePage" />

        <ul class="tools">
          <li class="filters" @click="isNavfiltersVisible=!isNavfiltersVisible" v-if="activePage!='settings'">
            <svg
              width="971.99px"
              height="971.99px"
              enable-background="new 0 0 971.986 971.986"
              version="1.1"
              viewBox="0 0 971.986 971.986"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m370.22 459.3c10.2 11.1 15.8 25.6 15.8 40.6v442c0 26.601 32.1 40.101 51.1 21.4l123.3-141.3c16.5-19.8 25.6-29.601 25.6-49.2v-272.8c0-15 5.7-29.5 15.8-40.601l353.8-383.9c26.5-28.8 6.101-75.5-33.1-75.5h-873c-39.2 0-59.7 46.6-33.1 75.5l353.8 383.8z"
              /></svg
            >Filters
          </li>
          
            <li class="settings">
              <a href="settings.html">
              <svg
                enable-background="new 0 0 268.765 268.765"
                version="1.1"
                viewBox="0 0 268.765 268.765"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m267.92 119.46c-0.425-3.778-4.83-6.617-8.639-6.617-12.315 0-23.243-7.231-27.826-18.414-4.682-11.454-1.663-24.812 7.515-33.231 2.889-2.641 3.24-7.062 0.817-10.133-6.303-8.004-13.467-15.234-21.289-21.5-3.063-2.458-7.557-2.116-10.213 0.825-8.01 8.871-22.398 12.168-33.516 7.529-11.57-4.867-18.866-16.591-18.152-29.176 0.235-3.953-2.654-7.39-6.595-7.849-10.038-1.161-20.164-1.197-30.232-0.08-3.896 0.43-6.785 3.786-6.654 7.689 0.438 12.461-6.946 23.98-18.401 28.672-10.985 4.487-25.272 1.218-33.266-7.574-2.642-2.896-7.063-3.252-10.141-0.853-8.054 6.319-15.379 13.555-21.74 21.493-2.481 3.086-2.116 7.559 0.802 10.214 9.353 8.47 12.373 21.944 7.514 33.53-4.639 11.046-16.109 18.165-29.24 18.165-4.261-0.137-7.296 2.723-7.762 6.597-1.182 10.096-1.196 20.383-0.058 30.561 0.422 3.794 4.961 6.608 8.812 6.608 11.702-0.299 22.937 6.946 27.65 18.415 4.698 11.454 1.678 24.804-7.514 33.23-2.875 2.641-3.24 7.055-0.817 10.126 6.244 7.953 13.409 15.19 21.259 21.508 3.079 2.481 7.559 2.131 10.228-0.81 8.04-8.893 22.427-12.184 33.501-7.536 11.599 4.852 18.895 16.575 18.181 29.167-0.233 3.955 2.67 7.398 6.595 7.85 5.135 0.599 10.301 0.898 15.481 0.898 4.917 0 9.835-0.27 14.752-0.817 3.897-0.43 6.784-3.786 6.653-7.696-0.451-12.454 6.946-23.973 18.386-28.657 11.059-4.517 25.286-1.211 33.281 7.572 2.657 2.89 7.047 3.239 10.142 0.848 8.039-6.304 15.349-13.534 21.74-21.494 2.48-3.079 2.13-7.559-0.803-10.213-9.353-8.47-12.388-21.946-7.529-33.524 4.568-10.899 15.612-18.217 27.491-18.217l1.662 0.043c3.853 0.313 7.398-2.655 7.865-6.588 1.184-10.105 1.198-20.383 0.06-30.561zm-133.32 60.03c-24.718 0-44.824-20.106-44.824-44.824 0-24.717 20.106-44.824 44.824-44.824 24.717 0 44.823 20.107 44.823 44.824 0 24.718-20.106 44.824-44.823 44.824z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                /></svg
              >Settings</a>
            </li>
          
        </ul>
      </div>
    </div>
    <div class="navfilter" v-if="isNavfiltersVisible">
      <div class="wrap">
        <select name="time">
          <option value="time">Time</option>
          <option value="Day">Past 24 hours</option>
          <option value="Week">Past week</option>
          <option value="Month">Past month</option>
        </select>
        <a href="#">Clear all</a>
      </div>
    </div>
  </div>
</template>

<script>
// import SearchBox from "../components/SearchBox";
import SearchCategories from "../components/SearchCategories";
export default {
  name: "Navigation",
  props: {
    activePage: String,
    searchTerm: String,
  },
  components: {
    SearchCategories,
  },
  beforeMount() {
    this.formSearchTerm = this.searchTerm
  },
  data: () => ({
    isModalMenuVisible: false,
    isNavfiltersVisible: false,
    formSearchTerm: null,
  }),
};
</script>

<style scoped>
.wrap {
  width: 100%;
  max-width: 784px;
}

#nav {
  -webkit-box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.05);
}
#nav .wrap {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.navsearch .install {
  top: calc(50% - 20px);
}

.navsearch {
  padding: 30px 40px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  min-width: 900px;
  position: relative;
}

.navsettings,
.navfilter {
  padding: 10px 40px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.navfilter {
  /* display: none; */
  background: #3d3d3d;
}

.navfilter .wrap {
  justify-content: flex-start !important;
}

.navfilter select {
  height: 26px;
  min-width: 125px;
  padding: 0 10px;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  border: 1px solid #737373;
  border-radius: 60px;
  font-family: "Poppins", sans-serif;
  -webkit-appearance: none;
  outline: none;
  background: url("../assets/img/icons/droparrow.svg") no-repeat center right
    10px;
  background-size: 10px;
  margin-right: 10px;
}

.navfilter select option {
  color: #3d3d3d;
}

.navfilter a {
  font-size: 12px;
  text-decoration: none;
  color: #eee;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.navfilter a:hover {
  color: #ccc;
}

.hamburger {
  position: absolute;
  right: 30px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  background: #eee;
  border-radius: 50%;
}

.hamburger svg {
  fill: #3d3d3d;
  transition: fill 0.2s ease;
}

.hamburger:hover svg {
  fill: #e13e34;
}

#modalmenu {
  position: absolute;
  right: 30px;
  top: 90px;
  width: 250px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  /* display: none;s */
  font-size: 14px;
  z-index: 101;
}

#modalmenu ul {
  list-style: none;
}

#modalmenu ul li {
  line-height: 40px;
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  transition: background 0.3s ease;
}

#modalmenu ul li:hover {
  background: #f7f7f7;
}

#modalmenu ul li:last-child {
  border: none;
}
#modalmenu a {
  color: #2a2a2a;
  text-decoration: none;
  transition: all 0.3s ease;
}

#modalmenu a:hover {
  color: #e14036;
}

ul.tools {
  display: inline-flex;
  list-style: none;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

ul.tools a {
  text-decoration: none;
  color: #3d3d3d;
}

ul.tools li {
  border: 1px solid #ddd;
  border-radius: 60px;
  padding: 5px 12px;
  margin: 0 0 0 10px;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

ul.tools li:hover {
  background: #eee;
}

ul.tools li svg {
  margin-right: 5px;
  fill: #3d3d3d;
}

ul.tools li.active {
  color: #e14036;
  font-weight: 500;
}

ul.tools li.active svg {
  fill: #e14036;
}

.filters svg {
  width: 12px;
  height: 12px;
}

.settings svg {
  width: 14px;
  height: 14px;
}

.searchbox {
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
}

.searchbox input {
  width: 100%;
  max-width: 680px;
  border: 1px solid #ddd;
  border-radius: 60px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 25px;
  height: 50px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
  letter-spacing: -0.3px;
  font-family: "Poppins", sans-serif;
}

.searchbox input:focus {
  border: 1px solid #949494;
  border-radius: 70px;
  outline: none;
  font-size: 16px;
}

::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #aaa;
  font-size: 16px;
  letter-spacing: -0.3px;
}

.searchbox button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: -45px;
  border: none;
  padding: 13px;
  background: #eee;
  transition: all 0.3s ease;
  cursor: pointer;
}

.searchbox button svg {
  fill: #3d3d3d;
  transition: all 0.2s ease;
}

.searchbox button:hover svg {
  fill: #e13e34;
}



</style>